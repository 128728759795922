import type { MouseEventHandler } from 'react';
import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import ExecutionEnvironment from 'exenv';

import { cn } from 'helpers/classnames';
import SearchInlineRecos from 'components/search/SearchInlineRecos';
import SponsoredAdsWrapper from 'components/search/SponsoredAdsWrapper';
import { evRecommendationClick } from 'events/recommendations';
import useMartyContext from 'hooks/useMartyContext';
import AdsRecosRow from 'components/search/AdsRecosRow';
import { track } from 'apis/amethyst';
import type { Product } from 'constants/searchTypes';
import ProductSearch from 'components/landing/ProductSearch';
import type { SponsoredAdProduct } from 'apis/sponsoredAds';
import { getProductRelations } from 'actions/products';
import { fetchSearchInlineRecos, setUrlUpdated } from 'actions/search';
import usePrevious from 'hooks/usePrevious';

import css from 'styles/components/search/inlineAdSlot.scss';

interface PortalProps {
  WrapperEl?: string;
  titleClassName?: any;
  className: string;
  rowIndex?: number;
  attributes: { [key: string]: any };
  children?: Element;
}

interface Props {
  sponsoredAds: SponsoredAdProduct[];
  [key: string]: any;
}

export const InlineAdSlot = ({
  trackSponsoredAdImpressions,
  makeCardClick,
  heartsInfo,
  inlineRecos,
  filters: { sort, page, term, originalTerm },
  crossSiteRecos,
  isLoadingSymphony,
  sponsoredAds,
  getProductRelations,
  productCardsCount,
  rowIndex,
  fetchSearchInlineRecos
}: Props) => {
  const {
    marketplace: {
      search: { msaMelodyImageParams, hasSponsoredAds, searchInlineRecosRowIndex, showHorizontalInlineSearchRecos }
    }
  } = useMartyContext();

  const prevOriginalTerm = usePrevious(originalTerm);

  const shouldHaveRecos = useCallback(
    () => showHorizontalInlineSearchRecos && ExecutionEnvironment.canUseDOM && page === 0,
    [page, showHorizontalInlineSearchRecos]
  );

  useEffect(() => {
    if (inlineRecos) {
      const styleIds = inlineRecos.recos.map((reco: { styleId: string }) => reco.styleId);
      getProductRelations(styleIds);
    }
  }, [inlineRecos]);

  useEffect(() => {
    if (!originalTerm || !shouldHaveRecos() || prevOriginalTerm === originalTerm) {
      return;
    }

    fetchSearchInlineRecos(originalTerm);
  }, []);

  const hasDefaultSort = !sort || sort?.bestForYou || sort?.relevance || !Object.keys(sort).length;
  const hasMarketplaceRecos = crossSiteRecos?.products?.length > 3 && !page && hasDefaultSort;
  const hasInlineRecos = originalTerm && inlineRecos?.recos?.length >= 2;
  const numSponsoredAds = sponsoredAds?.length;
  const sponsoredAdsToShow = !!numSponsoredAds && rowIndex && hasSponsoredAds;

  const makeSponsoredAdClick = (product: Product, index: number) => {
    const onCardClick = makeCardClick(product, index);
    const { productId, styleId, colorId } = product;
    return (e: MouseEventHandler) => {
      track(() => [
        evRecommendationClick,
        {
          index,
          recommendationType: 'PRODUCT_RECOMMENDATION',
          productIdentifiers: {
            productId,
            styleId,
            colorId
          },
          recommendationSource: 'MICROSOFT',
          widgetType: 'MICROSOFT_TOP_BLOCK',
          sourcePage: 'SEARCH_PAGE'
        }
      ]);

      onCardClick(e);
    };
  };

  // default is P13N product ads
  let portalProps: PortalProps = { className: '', attributes: {} };
  let portalContent;

  if (isLoadingSymphony) {
    return null;
  }

  if (hasInlineRecos) {
    portalProps = {
      WrapperEl: 'aside',
      className: css.inlineAdWrapper,
      rowIndex: searchInlineRecosRowIndex,
      attributes: {
        'data-reco-count': inlineRecos.recos.length,
        'data-test-id': 'searchResultsRecos'
      }
    };

    portalContent = (
      <SearchInlineRecos
        titleClassName={css.inlineTitle}
        heartsInfo={heartsInfo}
        inlineRecos={inlineRecos}
        msaImageParams={msaMelodyImageParams}
        searchTerm={term}
      />
    );
  }

  // new implementation of sponsored ads endpoint.
  if (numSponsoredAds > 0) {
    // we need a better way to handle this.
    // const hasSecondRowAds = true || hasInlineRecos || sponsoredAdsToShow || hasMarketplaceRecos;

    //if second row ads are showing, we need to remove one from the index to get correct the desired row.
    portalProps = {
      className: cn([css.sponsoredAdsWrapper, `sponsoredAds-${rowIndex}`]),
      rowIndex,
      attributes: { 'data-test-id': `sponsoredResultsAds-${rowIndex}` }
    };

    portalContent = (
      <SponsoredAdsWrapper
        numSponsoredAds={numSponsoredAds}
        trackSponsoredAdImpressions={trackSponsoredAdImpressions} //tracks viewUrl per product.
        sponsoredAdsResults={sponsoredAds}
        msaImageParams={msaMelodyImageParams}
        makeSponsoredAdClick={makeSponsoredAdClick}
        sponsoredAdsToShow={sponsoredAdsToShow}
      />
    );
  }

  // Marketplace Recos
  if (hasMarketplaceRecos) {
    portalProps = {
      className: css.marketplaceRecosWrapper,
      rowIndex: searchInlineRecosRowIndex,
      attributes: { 'data-test-id': 'marketplaceRecos' }
    };

    portalContent = (
      <ProductSearch
        slotIndex={0}
        slotName={'marketplaceRecos'}
        slotHeartsData={{}}
        onComponentClick={() => {}}
        shouldLazyLoad={false}
        isFullWidth={false}
        slotDetails={crossSiteRecos}
      />
    );
  }

  return (
    <AdsRecosRow {...portalProps} cardCount={productCardsCount} debounceWait={100}>
      {portalContent}
    </AdsRecosRow>
  );
};

interface InlineSponsoredAdsProps extends Props {
  term?: string;
  firstTenStyleIds?: string[];
}

export const InlineSponsoredAds = (props: InlineSponsoredAdsProps) => {
  const bottomSlotDisplayFourAds = props.sponsoredAds?.slice(0, 4);

  return <ConnectedInlineAdSlot {...props} sponsoredAds={bottomSlotDisplayFourAds} forceNoMsftAds={true} />;
};

const ConnectedInlineAdSlot = connect(null, { getProductRelations, fetchSearchInlineRecos, setUrlUpdated })(InlineAdSlot);
export default ConnectedInlineAdSlot;
