import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { weakMapMemoize } from 'reselect';

import { cn } from 'helpers/classnames';
import { evSearchPageClickthrough, evSwatchImpressionWrapper, nameSwatchType } from 'events/search';
import { Loader } from 'components/Loader';
import { track } from 'apis/amethyst';
import { trackEvent } from 'helpers/analytics';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useMartyContext from 'hooks/useMartyContext';
import InlineProductCardAd from 'components/common/InlineProductCardAd';
import { getHeartProps } from 'helpers/HeartUtils';
import { getLowStockLabelStatus } from 'helpers/cardUtils';
import ColorSwatchWrapper from 'components/search/ColorSwatches';
import InlineAdSlot, { InlineSponsoredAds } from 'components/search/InlineAdSlot';
import useRowHasBadge from 'hooks/useRowHasBadge';
import useWindowSize from 'hooks/useWindowSize';
import ProductUtils from 'helpers/ProductUtils';
import { getBadgeId } from 'helpers/BadgeUtils';
import { useSponsoredAds } from 'hooks/useSponsoredAds';

import css from 'styles/components/search/products.scss';

const MAX_ANIMATION_FRAMES = 9;

const makeMakeCardClickExpanded = weakMapMemoize(
  (onProductClicked, dispatch, product, index, productId, styleId, isLowStock, badgeId, page) => (e, currentStyleId) => {
    const position = index + 1;
    onProductClicked(position, productId, styleId, e);
    dispatch((dispatch, getState) => {
      const state = getState();
      const eventPayload = {
        pageNumber: page + 1,
        pageResultNumber: position,
        product,
        numberOfHearts: state?.products?.heartsList?.[styleId],
        isLowStock,
        currentStyleId,
        badgeId
      };
      track(() => [evSearchPageClickthrough, eventPayload]);
    });
  }
);

const makeMakeCardClick = weakMapMemoize((onProductClicked, dispatch, page) => (product, index) => {
  const { productId, styleId, isLowStock, badges } = product;
  const badgeId = getBadgeId(badges);
  return makeMakeCardClickExpanded(onProductClicked, dispatch, product, index, productId, styleId, isLowStock, badgeId, page);
});

const MemoColorSwatchWrapper = memo(ColorSwatchWrapper);

export const Products = ({
  products,
  onProductClicked,
  onProductMediaHovered,
  filters,
  productClass,
  products: { list, allProductsCount, inlineRecos, trustedRetailers, isLoading, oosMessaging, executedSearchUrl },
  inlineBannerData,
  trackSponsoredAdImpressions,
  isCustomer,
  heartProduct,
  unHeartProduct,
  toggleHeartingLoginModal,
  makeScrollButton,
  crossSiteRecos,
  isLoadingSymphony
}) => {
  const {
    testId,
    marketplace: {
      features: { showRatings },
      search: { msaMelodyImageParams, hasCrossSiteSearches, hasSponsoredAds, sponsoredAdsSlotOneRowIndex, sponsoredAdsSlotTwoRowIndex }
    }
  } = useMartyContext();
  const [animationTimerIndex, setAnimationTimerIndex] = useState(0);
  const { formattedList } = useRowHasBadge(list);
  const { page, selected, term } = filters;
  const { width: windowWidth } = useWindowSize();
  const dispatch = useDispatch();
  useEffect(() => {
    const { list } = products;
    // Start a global animation timer for any products that have detail zoom.  Having a top level timer keeps them in sync regardless
    // of re-renders or lazyloading, which will tick every 800ms
    let intervalId;
    const startAnimationTimer = () => {
      intervalId = setInterval(() => {
        setAnimationTimerIndex(index => {
          let newIndex = index + 1;
          // Reset the cycle
          if (newIndex > MAX_ANIMATION_FRAMES) {
            newIndex = 0;
          }
          return newIndex;
        });
      }, 800);
    };
    list.some(item => item.animationImages?.length) && startAnimationTimer(); // if we find a product that has animation images, start timer
    return () => clearInterval(intervalId);
  }, [products]);

  // Track swatch impressions
  useEffect(() => {
    if (!windowWidth) {
      return;
    }
    const swatchImpressions = [];
    const { allProductsCount, list, trustedRetailers } = products;
    // * visible swatches are dynamically shown but this hardcoding will get us close enough to accuracy given mobile / tablet / desktop viewports for search
    const isMobile = windowWidth > 0;
    const isDesktop = windowWidth > 1138;
    const visibleSwatches = isDesktop ? 5 : isMobile ? 3 : 7;

    if (allProductsCount > 0) {
      const combinedProducts = hasCrossSiteSearches ? [...trustedRetailers, ...list] : [...list];

      combinedProducts.forEach(({ styleId, relatedStyles } = {}) => {
        if (relatedStyles) {
          const { visibleFabricSwatches, groupedSwatches } = ProductUtils.groupVisibleSwatches(relatedStyles, visibleSwatches);

          const swatches = visibleFabricSwatches.map(({ color, styleId, swatchUrl }) => ({
            styleId,
            supplementalData: {
              swatchType: nameSwatchType(color, swatchUrl)
            }
          }));

          const groupedSwatchesProductIdentifiers = groupedSwatches.map(({ color, styleId, swatchUrl }) => ({
            styleId,
            supplementalData: {
              swatchType: nameSwatchType(color, swatchUrl)
            }
          }));

          swatchImpressions.push({
            mainStyleId: styleId,
            pageType: 'SEARCH_PAGE',
            swatches,
            groupedSwatches: groupedSwatchesProductIdentifiers
          });
        }
      });
    }

    if (swatchImpressions.length > 0) {
      track(() => [evSwatchImpressionWrapper, swatchImpressions]);
    }
  }, [hasCrossSiteSearches, products.allProductsCount, products.list, products.trustedRetailers, windowWidth]);

  const firstTenStyleIds = ProductUtils.getFirstTenStyleIds(list);

  const sponsoredAds = useSponsoredAds({
    filters: { ...selected?.singleSelects, ...selected?.multiSelects },
    pageType: Object.keys(filters).length > 0 ? 'Category' : 'SearchResults',
    term,
    matchStyles: firstTenStyleIds
  });

  const sponsoredAdsResultsTopSlot = sponsoredAds?.results.top;
  const sponsoredAdsResultsBottomSlot = sponsoredAds?.results.bottom;

  const makeCardClick = makeMakeCardClick(onProductClicked, dispatch, page);

  const makeProducts = list => {
    if (allProductsCount > 0) {
      const combinedProducts = hasCrossSiteSearches ? [...trustedRetailers, ...list] : [...list];

      const heartsProps = getHeartProps(
        {
          trackEvent,
          isCustomer,
          heartProduct,
          toggleHeartingLoginModal,
          unHeartProduct,
          isDisplayCount: true,
          hasHearting: true
        },
        {
          heartEventName: 'TE_SEARCH_PRODUCT_HEART',
          unHeartEventName: 'TE_SEARCH_PRODUCT_UNHEART'
        }
      );

      const allProductCards = combinedProducts.map((v, i) => {
        const isLowStock = getLowStockLabelStatus({ ...v });
        v.isLowStock = isLowStock;
        const product = {
          ...v,
          index: i
        };
        const dataTestId = trustedRetailers[i]?.isTrustedRetailer ? 'searchResultsCrossSiteProduct' : 'searchResult';

        return (
          <MemoColorSwatchWrapper
            {...product}
            heartsInfo={heartsProps}
            key={`${product.styleId}-${product.colorId}`}
            testId={testId(dataTestId)}
            animationTimerIndex={animationTimerIndex}
            msaImageParams={msaMelodyImageParams}
            onClick={makeCardClick(v, i)}
            onProductMediaHovered={onProductMediaHovered}
            link={product.productSeoUrl || product.productUrl || null}
            showRatings={showRatings}
            className={css.card}
          />
        );
      });

      if (inlineBannerData?.placement) {
        const placement = parseInt(inlineBannerData.placement, 10);
        const adIndex = placement - 1;
        const canRenderAd =
          !isNaN(placement) &&
          placement > 0 &&
          allProductCards.length + 1 >= placement &&
          inlineBannerData?.heading &&
          inlineBannerData?.src &&
          inlineBannerData?.mobilesrc;

        canRenderAd && allProductCards.splice(adIndex, 0, <InlineProductCardAd key="searchInlineHero" term={term} {...inlineBannerData} />);
      }

      let productCardsCount = 0;

      if (allProductsCount) {
        productCardsCount = hasCrossSiteSearches ? trustedRetailers.length + allProductCards.length : allProductCards.length;
      }

      const displaySecondRowAds = productCardsCount >= 10;

      return (
        <div id="products" data-test-id={testId('productCardContainer')} className={cn(css.products, productClass)}>
          {allProductCards}
          {executedSearchUrl && (
            <>
              <InlineAdSlot
                trackSponsoredAdImpressions={trackSponsoredAdImpressions}
                makeCardClick={makeCardClick}
                msaImageParams={msaMelodyImageParams}
                heartsInfo={heartsProps}
                inlineRecos={inlineRecos}
                filters={filters}
                crossSiteRecos={crossSiteRecos}
                isLoadingSymphony={isLoadingSymphony}
                productCardsCount={productCardsCount}
                rowIndex={sponsoredAdsSlotOneRowIndex}
                sponsoredAds={sponsoredAdsResultsTopSlot}
              />
              {hasSponsoredAds && displaySecondRowAds && (
                <InlineSponsoredAds
                  trackSponsoredAdImpressions={trackSponsoredAdImpressions}
                  makeCardClick={makeCardClick}
                  msaImageParams={msaMelodyImageParams}
                  heartsInfo={heartsProps}
                  filters={filters}
                  term={term}
                  isLoadingSymphony={isLoadingSymphony}
                  productCardsCount={productCardsCount}
                  firstTenStyleIds={firstTenStyleIds}
                  rowIndex={sponsoredAdsSlotTwoRowIndex}
                  sponsoredAds={sponsoredAdsResultsBottomSlot}
                />
              )}
            </>
          )}
        </div>
      );
    }
    return null; // no results view handled by NoSearchResults.js
  };

  // render
  return (
    <div id="searchPage" className={'searchPage'}>
      <h2>Search Results</h2>
      {oosMessaging && (
        <p className={css.oosMessaging} data-test-id={testId('oosMessage')}>
          {oosMessaging}
        </p>
      )}
      {isLoading || formattedList.length <= 0 ? <Loader /> : makeProducts(formattedList)}
      {makeScrollButton && makeScrollButton()}
    </div>
  );
};

Products.displayName = 'Products';

Products.propTypes = {
  filters: PropTypes.object.isRequired,
  layout: PropTypes.string,
  onProductClicked: PropTypes.func.isRequired,
  onProductMediaHovered: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number,
  products: PropTypes.object.isRequired,
  showRatings: PropTypes.bool.isRequired
};

Products.contextTypes = {
  testId: PropTypes.func,
  marketplace: PropTypes.object
};

export default withErrorBoundary('Products', Products);
